<!--设备列表-->
<template>
	<div class="page">
		<Tables :showSeq="false" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true"
			:showSearch="true" :showAdd="$hasAccess('group.bill/index')" addName="新增组织机构" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input
						v-model="searchFormData.operator_name"
						size="small"
						clearable
						placeholder="按组织机构简称查询"
				></el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="operator_name" title="组织机构简称" align="center" min-width="30px"/>
			<vxe-table-column slot="table-item" field="OperatorID" title="组织机构代码" align="center" min-width="30px" />
			<vxe-table-column slot="table-item" field="OperatorSecret" title="运营商秘钥" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="SigSecret" title="签名秘钥" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="DataSecret" title="数据加密秘钥" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="DataSecretIV" title="初始化向量" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="url" title="接口地址" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="Ip" title="IP白名单" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="status" title="参数类型" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{row.status === 1 ? '线上' : '测试'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_time" title="录入时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="200">
				<template v-slot="{ row }">
					<el-button
							size="small"
							plain
							@click="edit(row)"
							v-if="$hasAccess('agent.operator/edit')"
					>编辑</el-button>
					<el-button
							size="small"
							plain
							@click="deleteOne(row)"
					>删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 新增 -->
		<el-dialog title="新增组织机构" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="组织机构简称" prop="operator_name">
					<el-input v-model="addDialogFormData.operator_name" placeholder="组织机构简称" clearable></el-input>
				</el-form-item>
				<el-form-item label="组织机构代码" prop="OperatorID">
					<el-input v-model="addDialogFormData.OperatorID" placeholder="组织机构代码" clearable></el-input>
				</el-form-item>
				<el-form-item label="运营商秘钥" prop="OperatorSecret">
					<el-input v-model="addDialogFormData.OperatorSecret" placeholder="运营商秘钥" clearable></el-input>
				</el-form-item>
				<el-form-item label="签名秘钥" prop="SigSecret">
					<el-input v-model="addDialogFormData.SigSecret" placeholder="签名秘钥" clearable></el-input>
				</el-form-item>
				<el-form-item label="数据加密秘钥" prop="DataSecret">
					<el-input v-model="addDialogFormData.DataSecret" placeholder="数据加密秘钥" clearable></el-input>
				</el-form-item>
				<el-form-item label="初始化向量" prop="DataSecretIV">
					<el-input v-model="addDialogFormData.DataSecretIV" placeholder="初始化向量" clearable></el-input>
				</el-form-item>
				<el-form-item label="接口地址" prop="url">
					<el-input v-model="addDialogFormData.url" placeholder="接口地址" clearable></el-input>
				</el-form-item>
				<el-form-item label="IP白名单" prop="Ip">
					<el-input v-model="addDialogFormData.Ip" placeholder="IP白名单" clearable></el-input>
				</el-form-item>
				<el-form-item
						label="参数类型"
						prop="status"
				>
					<el-radio-group v-model="addDialogFormData.status">
						<el-radio :label="1">线上</el-radio>
						<el-radio :label="2">测试</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="自己的组织" prop="OperatorID_My">
					<el-select v-model="addDialogFormData.OperatorID_My" clearable>
						<el-option v-for="item in tableData" :label="item.operator_name" :value="item.OperatorID"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()" v-if="$hasAccess('agent.operator/add')">保 存  </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
		<!-- 编辑 -->
		<el-dialog title="编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="组织机构简称" prop="operator_name">
					<el-input v-model="editDialogFormData.operator_name" placeholder="组织机构简称" clearable></el-input>
				</el-form-item>
				<el-form-item label="组织机构代码" prop="OperatorID">
					<el-input v-model="editDialogFormData.OperatorID" placeholder="组织机构代码" clearable></el-input>
				</el-form-item>
				<el-form-item label="运营商秘钥" prop="OperatorSecret">
					<el-input v-model="editDialogFormData.OperatorSecret" placeholder="运营商秘钥" clearable></el-input>
				</el-form-item>
				<el-form-item label="签名秘钥" prop="SigSecret">
					<el-input v-model="editDialogFormData.SigSecret" placeholder="签名秘钥" clearable></el-input>
				</el-form-item>
				<el-form-item label="数据加密秘钥" prop="DataSecret">
					<el-input v-model="editDialogFormData.DataSecret" placeholder="数据加密秘钥" clearable></el-input>
				</el-form-item>
				<el-form-item label="初始化向量" prop="DataSecretIV">
					<el-input v-model="editDialogFormData.DataSecretIV" placeholder="初始化向量" clearable></el-input>
				</el-form-item>
				<el-form-item label="接口地址" prop="url">
					<el-input v-model="editDialogFormData.url" placeholder="接口地址" clearable></el-input>
				</el-form-item>
				<el-form-item label="IP白名单" prop="Ip">
					<el-input v-model="editDialogFormData.Ip" placeholder="IP白名单" clearable></el-input>
				</el-form-item>
				<el-form-item
						label="参数类型"
						prop="status"
				>
					<el-radio-group v-model="editDialogFormData.status">
						<el-radio :label="1">线上</el-radio>
						<el-radio :label="2">测试</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="自己的组织" prop="OperatorID_My">
					<el-select v-model="editDialogFormData.OperatorID_My" clearable>
						<el-option v-for="item in tableData" :label="item.operator_name" :value="item.OperatorID"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveedit()" v-if="$hasAccess('group.wuye/edit')">保 存 编 辑 </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import Tables from "@/components/tables";
	export default {
		name: "property-bill-management",
		components: {
			Tables,
		},
		data() {
			return {
				// table信息
				tableName: "设备清单",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				billList: [],
				selecttime: {},
				editselecttime: {},
				groupList: [],
				groupLoading: false,
				payStatus: {
					0: {
						txt: '未结算',
						class: 'sd_error',
					},
					1: {
						txt: '已结算',
						class: 'sd_spare'
					}
				},
				addDialogShow: false,
				addDialogFormData: {},
				editDialogShow:false,
				editDialogFormData: {},
				rules: {
					operator_name: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					OperatorID: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					OperatorSecret: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					SigSecret: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					DataSecret: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					DataSecretIV: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					status: [
						{ required: true, message: '必填项', trigger: 'change' }
					],
					url: [
						{ required: true, message: '必填项', trigger: 'change' }
					]
				},
			};
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
			this.$refs.xTable.refreshTable();
		},
		methods: {
			// 初始化请求
			init() {
			},
			// 获取列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: 100//pageSize
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				const res = await this.$api.Agent.GetOperatorList(params,searchData);
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 重置form
			resetFormData () {
				this.addDialogFormData = {
					status: 1
				}
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields()
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 重置查询
			clearSearch() {
				this.searchFormData = {};
			},
			// 新增设备
			addOne() {
				this.resetFormData()
				this.addDialogShow = true;
				this.selecttime='';
			},
			saveAdd() {
				this.$refs.addForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.addDialogFormData)
						}
						await this.$api.Agent.AddOperator(params)
						this.$notify({
							title: '成功',
							message: '新增成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			async edit (row) {
				this.editDialogFormData = {};
				this.editDialogShow = true;
				this.editselecttime={};
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				const res = await this.$api.Agent.GetOperatorDetail(params);
				if (res) {
					const {
						id,
						operator_name,
						OperatorID,
						OperatorSecret,
						SigSecret,
						DataSecret,
						DataSecretIV,
						status,
						url,
						Ip,
						OperatorID_My
					} = res;
					this.editDialogFormData = {
						id,
						operator_name,
						OperatorID,
						OperatorSecret,
						SigSecret,
						DataSecret,
						DataSecretIV,
						status,
						url,
						Ip,
						OperatorID_My
					};
					this.editDialogFormOriData = {
						id,
						operator_name,
						OperatorID,
						OperatorSecret,
						SigSecret,
						DataSecret,
						DataSecretIV,
						status,
						url,
						Ip,
						OperatorID_My
					};
				}
				this.editDialogShow = true;
			},
			saveedit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Agent.EditOperator(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 删除
			deleteOne (row) {
				this.$confirm('确定要删除该组织信息吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Agent.DeleteOperator(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			closeDialog() {
				this.addDialogShow = false;
				this.editDialogShow = false;
			}
		},
	};
</script>
<style lang="less" scoped>
	.from-item-width {
		width: 180px;
	}

	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
	}

	.sd_error {
		color: #f56c6c;
	}

	.sd_total {
		color: #b28e2f;
	}

	.add-dialog-form {
		.select {
			width: 100%;
		}
	}
</style>
<style>
	.el-popover {
		text-align: right;
	}
</style>
